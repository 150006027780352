<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="details.distribution_disabled"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/distribution/minors'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'DistributionDisabledOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    next()
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.$store.getters.peopleDistributionPrimary)
        number += this.$store.getters.peopleDistributionPrimary.length
      if (this.$store.getters.groupsDistributionPrimary)
        number += this.$store.getters.groupsDistributionPrimary.length
      if (this.details.distribution_all_die_backup_table) number += 1
      if (
        !this.$store.getters.groupsDistributionPrimary.length &&
        this.$store.getters.peopleDistributionPrimary.length === 1 &&
        this.$store.getters.peopleDistributionPrimary[0]
          .distribution_backup_children_no
      )
        number -= 1

      return number + 3
    },
    isValidated() {
      return true
    },
    forwardTo() {
      if (this.details.distribution_disabled)
        return '/distribution/disability_details'
      return '/distribution/check_and_confirm'
    },
    details() {
      return this.$store.getters.details
    }
  },
  data() {
    return {
      heading: 'Are any of your beneficiaries disabled and/or vulnerable?',
      subHeading:
        'Leaving an inheritance to a disabled or vulnerable beneficiary is a little more complicated. ' +
        "If any of your beneficiaries have a disability please select 'yes' - " +
        'particularly where there may be an entitlement to state benefits now or in the future. Equally if you ' +
        "consider any of those named as beneficiaries to be vulnerable please select 'yes'."
    }
  },
  methods: {
    save() {
      this.$store.commit('details', {
        distribution_disabled: !this.details.distribution_disabled
      })
      if (!this.details.distribution_disabled) {
        this.resetDisabledText()
      }
    },
    resetDisabledText() {
      this.$store.commit('details', {
        distribution_disabled_details: ''
      })
    }
  }
}
</script>

<style></style>
